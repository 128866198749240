import { ref, onMounted, onBeforeUnmount } from 'vue';

export default function (customLimits?: { min: number; max: number }) {
  const isMobile = ref(false);
  const isTablet = ref(false);
  const isCustomDevice = ref(false);

  function onWindowResize() {
    isMobile.value = window.innerWidth <= 768;
    isTablet.value = window.innerWidth <= 1024 && window.innerWidth > 768;
    if (customLimits) {
      isCustomDevice.value =
        window.innerWidth <= customLimits.max && window.innerWidth > customLimits.min;
    }
  }

  onMounted(() => {
    window.addEventListener('resize', onWindowResize);
    onWindowResize();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onWindowResize);
  });

  return {
    isMobile,
    isTablet,
    isCustomDevice,
  };
}
