import { inject } from 'vue';
import { subscribeKey, unsubscribeKey } from '@drapejs/core';

import { registerListingBlockKey, unregisterListingBlockKey } from '@/composables/useDynamicProductListing';

export default function (options) {
  const subscribe = inject(subscribeKey, () => Promise.resolve());
  const unsubscribe = inject(unsubscribeKey, () => Promise.resolve());

  const registerProductListingBlock = inject(registerListingBlockKey, () => {});
  const unregisterProductListingBlock = inject(unregisterListingBlockKey, () => {});

  const handler = (data) => {
    const products = data?.productGridBlockProducts?.products || [];
    options.setProducts(products);
  };

  const registerProductBlock = async (block) => {

    if (hasProductDataSource(block)) {
      subscribe(getProductBlockCacheKey(block), handler, { immediate: true });
    } else {
      await registerProductListingBlock?.(block.systemId, options);
    }
  };

  const unregisterProductBlock = (block) => {
    if (hasProductDataSource(block)) {
      unsubscribe(getProductBlockCacheKey(block), handler);
    } else {
      unregisterProductListingBlock?.(block.systemId);
    }
  };

  return {
    registerProductBlock,
    unregisterProductBlock,
  };
}

export function getProductBlockCacheKey(block) {
  const relationName = block.relationName ? `::r:${block.relationName}` : '';
  const baseProductId = block.baseProductId ? `::bp:${block.baseProductId}` : '';
  const variantId = block.variantId ? `::v:${block.variantId}` : '';

  return `products:block:${block.systemId}${relationName}${baseProductId}${variantId}`;
}

export function hasProductDataSource(block) {
  return block?.fields?.DataSource;
}
