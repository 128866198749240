/**
 * # Litium Dynamic Product Listing
 *
 * This composable is used to create a dynamic product listing based on
 * Distancify.LitiumAddOns.ElasticSearch. It has support for handling facets, but more importantly
 * it allows child blocks to receive a part of a listing's products in order for users to create
 * dynamic layouts which incorporates the listing itself, creating a seamless integration
 * between editorial content and the listing products.
 *
 * ## Using the module
 * The root page component should execute the default export of this module in it's setup()
 * function. This will set up the necessary data fetching from cache and provide functions
 * to child blocks for accessing the state.
 *
 * Child components can then use the getContext() function to inject the state and functions
 * they need.
 *
 * ## Working with Facets
 * The architecture for facets it's based on the idea that getContext() provides access to
 * the available facets, as well as the selected facets. This data is read-only.
 *
 * There are two ways to apply a new facet. You can either use drapeJS native navigate()
 * function. Or you can call the toggleFacetValue(facet, value) function provided by
 * getContext() function.
 *
 * The benefit of getContext() is that it will update the current state immediately, so the
 * user will not have to wait for a round-trip before selecting another facet value, or risking
 * overriding the previous selection.
 */

import { reactive, inject, ref, provide, InjectionKey, onMounted, watch, Ref, readonly, computed } from 'vue';
import { subscribeKey, unsubscribeKey, navigateKey, getItemKey, getServerItem } from '@drapejs/core';
import { isNode } from '@drapejs/runtime-context';
//import { useGA4 } from '@/composables/gtm';

import useContext from "./useContext";
import useWebsiteTexts from './useWebsiteTexts';
import useWindowResize from './useWindowResize';

const sortOptions: string[] = [
  'popularity',
  'nameasc',
  'namedesc',
  'priceasc',
  'pricedesc',
];
export interface CategoryBlockOptions {
  setProducts(products: [any]): void
  numProducts: number
}

export interface FacetHit {
  id: string
  name: string
  type: string
  isActive: boolean
  values: FacetValue[]
  selectedValues?: any[]
  range: {
    min: number
    max: number
  }
  selectedRange: {
    min: number
    max: number
  }
}

export interface FacetValue {
  id: string
  text: string
  count: number
  isActive: boolean
  value: string
  label: string
}

export interface FacetRangeValue {
  min: number
  max: number
}

export interface ListingData {
  facets: FacetHit[]
  hits: number
  activeFacets: {
    id: string
    type: string
    values: {
      id: string
      text: string
    }[]
  }[]
  products: any[]
  searchPhrase: string
}

export interface ActiveFacet {
  id: string
  name: string
  type?: string
  values: {
    value: string
    label: string
  }[]
}

export interface CategoryChild {
  name: string;
  url: string;
}

export interface Category {
  categorySystemId: string;
  children: CategoryChild[];
}

export const registerListingBlockKey: InjectionKey<(uuid: string, options: CategoryBlockOptions) => void> = Symbol('registerCategoryBlockKey');
export const unregisterListingBlockKey: InjectionKey<(uuid: string) => void> = Symbol('unregisterCategoryBlockKey');
export const categoryKey: InjectionKey<Ref<Category>> = Symbol("category");
export const allFacetsKey: InjectionKey<Ref<FacetHit[]>> = Symbol("allFacets");
export const productListingDataKey: InjectionKey<Ref<ListingData>> = Symbol('productListingData');
export const activeFacetsKey: InjectionKey<ActiveFacet[]> = Symbol('activeFacets');
export const toggleFacetValueKey: InjectionKey<(facet: FacetHit, value: FacetValue|FacetRangeValue) => void> = Symbol('toggleFacetValue');
export const clearFacetKey: InjectionKey<(facet: FacetHit) => void> = Symbol('clearFacetKey');
export const clearAllFacetsKey: InjectionKey<() => void> = Symbol('clearAllFacets');
export const sortOptionsKey: InjectionKey<string[]> = Symbol('sortOptions');
export const selectedSortOptionKey: InjectionKey<Ref<string>> = Symbol('selectedSort');
export const setSortOptionKey: InjectionKey<(option: string) => void> = Symbol('setSortOption');
export const listingFacetsKey: InjectionKey<Ref<FacetHit[]>> = Symbol('listingFacets');
export const facetsDrawerKey: InjectionKey<Ref<boolean>> = Symbol('facetsDrawer');
export const openFacetDrawerKey: InjectionKey<
  (facet?: FacetHit, showBackButton?: boolean) => void
> = Symbol('openFacetDrawer');
export const closeFacetDrawerKey: InjectionKey<() => void> =
  Symbol('closeFacetDrawer');
export const currentDrawerFacetKey: InjectionKey<Ref<FacetHit | undefined>> = Symbol('currentFacetDrawer');
export const showDrawerBackButtonKey: InjectionKey<Ref<boolean>> = Symbol('showDrawerBackButton');
export const isMobileKey: InjectionKey<Ref<boolean>> = Symbol('isMobile');
export const toggleElementAnimationKey: InjectionKey<(cssClass: string, element:HTMLDivElement) => void> = Symbol('toggleElementAnimation');
export const isSortDropwdownOpenKey: InjectionKey<Ref<boolean>> = Symbol('isSortDropwdownOpen');
export const productCountKey: InjectionKey<Ref<string>> = Symbol('productCount');


export default function () {
  const getItem = inject(getItemKey);

  const productBlocks = reactive({});
  const activeFacets = reactive<ActiveFacet[]>([]);

  const navigate = inject(navigateKey, () => Promise.resolve());
  const { route, formatPrice } = useContext();
  const { websiteText } = useWebsiteTexts();
  const { isMobile } = useWindowResize();

  let categoryCacheKey = getCategoryCacheKey(route.pathname);

  let categoryFacetsCacheKey = getCategoryFacetsCacheKey(
    route.pathname,
    route.query
  );

  let categoryProductsCacheKey = getCategoryProductsCacheKey(
    route.pathname,
    route.query
  );

  const cache = {
    bindCategory: inject(categoryCacheKey, () => Promise.resolve()),
    bindCategoryFacets: inject(categoryFacetsCacheKey, () => Promise.resolve()),
    bindCategoryProducts: inject(categoryProductsCacheKey, () =>
      Promise.resolve()
    ),
    subscribe: inject(subscribeKey, () => Promise.resolve()),
    unsubscribe: inject(unsubscribeKey, () => Promise.resolve()),
  };

  //const ga4 = useGA4();
  const category = ref<any>(cache.bindCategory());
  const categoryFacets = ref<any>(cache.bindCategoryFacets());
  const categoryProducts = ref<any>(cache.bindCategoryProducts());

  const remainingProducts = ref(categoryProducts.value?.products || []);
  const isLoadingProducts = ref(false);
  const selectedSortOption = ref(sortOptions[0]);
  const isFacetsDrawerOpen = ref(false);
  const currentDrawerFacet = ref<FacetHit | undefined>();
  const showDrawerBackButton = ref(true);
  const isSortDropwdownOpen = ref(false);

  if (route.query.sort) {
    selectedSortOption.value = sortOptions.find(r => r === route.query.sort) || sortOptions[0];
  }

  resetActiveFacets();

  provide(registerListingBlockKey, async (uuid, options) => {
    productBlocks[uuid] = options;
    await bindProducts();
  });

  provide(unregisterListingBlockKey, (uuid) => {
    delete productBlocks[uuid];
  });

  provide(categoryKey, category);
  provide(sortOptionsKey, sortOptions);
  provide(selectedSortOptionKey, readonly(selectedSortOption));
  provide(setSortOptionKey, (option: string) => {
    if (option !== selectedSortOption.value && sortOptions.find(r => r === option)) {
      selectedSortOption.value = option;
      navigate(generateCurrentUrlFromState());
    }
  });
  provide(facetsDrawerKey, isFacetsDrawerOpen);
  provide(currentDrawerFacetKey, currentDrawerFacet);
  provide(showDrawerBackButtonKey, showDrawerBackButton);
  provide(isMobileKey, isMobile)
  provide(isSortDropwdownOpenKey, isSortDropwdownOpen);

  const disableCategoryMenu = computed(() => isSortDropwdownOpen.value && isMobile.value);

  const listingFacets = computed(() => {
    if (typeof categoryFacets.value === 'object') {
      const list = Array.from(categoryFacets.value) as FacetHit[];
      const hits = categoryProducts.value?.hits || 0;
  
      return (
        list
          .filter((f) => {
            switch (f.id) {
              case '#Category':
                f.name = websiteText('category__facets_category').value;
                break;
              case '#Price':
                f.name = websiteText('category__facets_price').value;
                break;
              default:
                break;
            }
  
            if (f.type === 'TextOption' || f.type === '#Categories') {
              return (
                f.selectedValues?.length ||
                f.values.some((v) => v.count && v.count < hits)
              );
            }
  
            return true;
          })
          .sort((a, b) => {
            const activeA = activeFacets.some((f) => f.id === a.id);
            const activeB = activeFacets.some((f) => f.id === b.id);
  
            return !activeA && activeB ? 1 : -1;
          }) || []
      );
    }
  
    return [];
  });

  const hits = computed(() => categoryProducts?.value.hits || 0);

  const productCount = computed(() => {
    const hitCount = hits.value;
    const textKey =
      hitCount === 1 
          ? 'category__hits_count_singular' 
          : 'category__hits_count';
    return categoryProducts?.value
      ? websiteText(textKey, {
          count: hits.value,
        }).value
      : '';
  });

  provide(listingFacetsKey, listingFacets);
  provide(productCountKey, productCount);

  provide(activeFacetsKey, readonly(activeFacets));
  provide(toggleFacetValueKey, (facet: FacetHit, value: any) => {
    const activeFacet = activeFacets.find((r) => r.id === facet.id);

    if (facet.type === 'Range') {
      var rangeValue = value as FacetRangeValue;
      const activeValue = activeFacet?.values;

      if (activeValue) {
        activeFacets.splice(activeFacets.findIndex(r => r.id === facet.id), 1)
      }

      if (typeof rangeValue.min !== 'undefined' || typeof rangeValue.max !== 'undefined') {
        activeFacets.push({
          id: facet.id,
          name: facet.name,
          type: facet.type,
          values: [{
            value: `[${rangeValue.min}-${rangeValue.max}]`,
            label: `${formatPrice(+rangeValue.min)} - ${formatPrice(+rangeValue.max)}`,
          }],
        });
      }
    }
    else if (activeFacet?.type === 'Boolean') {
      activeFacets.splice(
        activeFacets.findIndex((r) => r.id === facet.id),
        1
      );
    }
    else if (activeFacet?.values) {
      const activeValue = activeFacet.values.find(
        (r: any) => r.value === value.value
      );
      if (activeValue) {
        activeFacet.values.splice(
          activeFacet?.values.findIndex((r: any) => r.value === value.value),
          1
        );

        if (activeFacet.values.length === 0) {
          activeFacets.splice(
            activeFacets.findIndex((r) => r.id === facet.id),
            1
          );
        }
      } else {
        activeFacet.values.push(value);
      }
    }
    else {
      activeFacets.push({
        id: facet.id,
        name: facet.name,
        type: facet.type,
        values: [value],
      });
    }

    navigate(generateCurrentUrlFromState());
  });
  provide(clearFacetKey, (facet: FacetHit, value: FacetValue) => {
    const activeFacetIdx = activeFacets.findIndex((r) => r.id === facet.id);
    if (activeFacetIdx >= 0) {
      activeFacets.splice(activeFacetIdx, 1);
      navigate(generateCurrentUrlFromState());
    }
  });
  provide(clearAllFacetsKey, (facet: FacetHit, value: FacetValue) => {
    if(activeFacets.length === 0) return;
    activeFacets.length = 0;
    navigate(generateCurrentUrlFromState());
  });

  provide(openFacetDrawerKey, (facet?: FacetHit, doShowBackButton: boolean = true) => {
    currentDrawerFacet.value = facet;
    isFacetsDrawerOpen.value = true;
    showDrawerBackButton.value = doShowBackButton;
  });

  provide(closeFacetDrawerKey, () => {
    isFacetsDrawerOpen.value = false;
  })

  provide(toggleElementAnimationKey, (cssClass: string, element:HTMLDivElement) => {
    if (element && isMobile.value) {
      requestAnimationFrame(() => {
        element.classList.add(cssClass);
        setTimeout(() => {
          element.classList.remove(cssClass);
        }, 1000);
      });
    }
  })

  onMounted(async () => {
    watch(
      () => route.href,
      async () => {
        isLoadingProducts.value = true;

        if(categoryCacheKey){
          cache.unsubscribe(categoryCacheKey, categoryCacheHnadler);
        }

        if (categoryFacetsCacheKey) {
          cache.unsubscribe(categoryFacetsCacheKey, facetsCacheHandler);
        }
        if (categoryProductsCacheKey) {
          cache.unsubscribe(categoryProductsCacheKey, productsCacheHandler);
        }

        if (route.query.sort) {
          selectedSortOption.value =
            sortOptions.find((r) => r === route.query.sort) || sortOptions[0];
        } else {
          selectedSortOption.value = sortOptions[0];
        }

        categoryCacheKey = getCategoryCacheKey(route.pathname);
        categoryFacetsCacheKey = getCategoryFacetsCacheKey(
          route.pathname,
          route.query
        );
        categoryProductsCacheKey = getCategoryProductsCacheKey(
          route.pathname,
          route.query
        );

        cache.subscribe(categoryCacheKey, categoryCacheHnadler, {
          immediate: true,
        });
        cache.subscribe(categoryFacetsCacheKey, facetsCacheHandler, {
          immediate: true,
        });
        cache.subscribe(categoryProductsCacheKey, productsCacheHandler, {
          immediate: true,
        });

        // if (route.query.search) {
        //   ga4.search(route.query.search);
        // }
      },
      { immediate: true }
    );
  });

  provide(allFacetsKey, categoryFacets);
  provide(productListingDataKey, categoryProducts);

  return {
    category,
    productBlocks,
    isLoadingProducts,
    disableCategoryMenu,
    productCount,
    isMobile,
    context: categoryProducts,
    facets: categoryFacets,
    gridProducts: remainingProducts,
  };

  function generateCurrentUrlFromState() {
    const query: string[] = [];
    if (activeFacets.length >= 0) {
      query.push(`facets=${stringifyFacets()}`);

      function stringifyFacets() {
        return encodeURIComponent(activeFacets.map(stringifyFacet).join(","));
      }
      function stringifyFacet(facet: ActiveFacet) {
        return `${facet.id}:${stringifyValues(facet)}`;
      }
      function stringifyValues(facet: ActiveFacet) {
        return facet.values.map((v: any) => v.value).join("|");
      }
    }
    if (selectedSortOption.value !== sortOptions[0]) {
      query.push(`sort=${selectedSortOption.value}`);
    }
    if (route.query.phrase) {
      query.push(route.query.phrase);
    }
    return route.pathname + (query.length > 0 ? `?${query.join("&")}` : "");
  }

  async function productsCacheHandler(val: any) {
    isLoadingProducts.value = false;
    categoryProducts.value = val;

    await bindProducts();
  }

  function categoryCacheHnadler(val: any){
    if(val?.hits === 1){
      category.value = val.categories[0];
    }
    else {
      category.value = { children: [] };
    }
  }

  function facetsCacheHandler(val: any) {
    const facets: FacetHit[] = [...(val || [])];
    categoryFacets.value = facets;
    resetActiveFacets();
  }

  async function bindProducts() {
    const products = categoryProducts.value?.products;
    if (!products) {
      remainingProducts.value = [];
      return;
    }

    let i = 0;
    for (let uid in productBlocks) {
      const block = productBlocks[uid];
      const take = parseInt(block.numProducts);
      const blockProducts = products.slice(i, i + take);
      block.setProducts(blockProducts);
      i += take;
    }

    if(activeFacets.length === 0){
      const highlightedProductsCacheKey = getCategoryHighlighetedProductsCacheKey(
        route.pathname,
        route.query
      )
      const highlightedProducts = isNode()
        ? getServerItem?.(highlightedProductsCacheKey)?.products || []
        : (await getItem?.(highlightedProductsCacheKey))?.products || [];

      remainingProducts.value = [...highlightedProducts, ...products.slice(i)];
    }
    else {
      remainingProducts.value = products.slice(i);
    }
  }

  function resetActiveFacets() {
    activeFacets.length = 0;

    if (categoryFacets.value?.length) {
      const facets = categoryFacets.value;
      facets.forEach((facet: any) => {
        const queryFacet = getFacetsFromQuery(route.query).find(
          (f: any) => f.fieldId == facet.id
        );
        const isSelected =
          facet.selectedValues?.length > 0 || queryFacet?.values?.length > 0;
        if (isSelected) {
          activeFacets.push({
            id: facet.id,
            name: facet.name,
            type: facet.type,
            values: getActiveFacetValues(facet, route.query),
          });
        }
      });
    }

    let searchPhrase = route.query?.search?.trim() || "";
    if (searchPhrase) {
      activeFacets.push({
        id: "search",
        name: "Search",
        type: "search",
        values: [
          {
            value: "search",
            label: `"${searchPhrase}"`,
          },
        ],
      } as any);
    }
  }

  function getActiveFacetValues(facet: any, query: any) {
    if (facet.type === "Boolean") {
      return [
        {
          value: facet.id,
          label: facet.name,
        },
      ];
    }
    if (facet.type === "Range") {
      return [
        {
          value: `[${facet.selectedRange.min}-${facet.selectedRange.max}]`,
          label: `${formatPrice(+facet.selectedRange.min)} - ${formatPrice(
            +facet.selectedRange.max
          )}`,
        },
      ];
    }
    const queryFacet = getFacetsFromQuery(query).find(
      (f: any) => f.fieldId == facet.id
    );
    const selectedValues = facet.values.filter(
      (f: any) =>
        facet.selectedValues.find((s: any) => s.value == f.value) ||
        queryFacet.values.find((f: any) => f == facet.id)
    );

    return (
      selectedValues.map((e: any) => {
        return { label: e.label, value: e.value };
      }) || []
    );
  }
}

export function getFacetsFromQuery(query: any) {
  return query?.facets
    ? decodeURIComponent(query.facets)
        .split(",")
        .map((e) => e.split(":"))
        .reduce((acc: any, cur) => {
          acc.push({
            fieldId: cur[0],
            values: cur[1].split("|"),
          });
          return acc;
        }, [])
    : [];
}

export function getCategoryFacetsCacheKey(
  path: string,
  query: { [key: string]: string }
) {
  return getCurrentCacheKey("facets", path, query);
}

export function getCategoryHighlighetedProductsCacheKey(
  path: string,
  query: { [key: string]: string }
) {
  return getCurrentCacheKey("highlighted:products", path, query);
}


export function getCategoryProductsCacheKey(
  path: string,
  query: { [key: string]: string }
) {
  return getCurrentCacheKey("products", path, query);
}

export function getCategoryCacheKey(path: string) {
  return `category:${path}`;
}

function getCurrentCacheKey(
  prefix: string,
  path: string,
  query: { [key: string]: string }
) {
  return `${prefix}:${path}${query?.facets ? `&facets=${query?.facets}` : ""}${
    query?.sort ? `&sort=${query.sort}` : ""
  }${query?.search ? `&phrase=${query.search}` : ""}`;
}

export function getContext() {
  return {
    category: inject(categoryKey, ref<Category>(<any>{})),
    listingData: inject(productListingDataKey, ref<ListingData>(<any>{})),
    allFacets: inject(allFacetsKey, ref<FacetHit[]>(<any>[])),
    listingFacets: inject(listingFacetsKey, ref<FacetHit[]>(<any>[])),
    activeFacets: inject(activeFacetsKey, []),
    sortOptions: inject(sortOptionsKey, []),
    selectedSortOption: inject(selectedSortOptionKey, ref('')),
    isFacetsDrawerOpen: inject(facetsDrawerKey, ref(false)),
    currentDrawerFacet: inject(currentDrawerFacetKey, ref(undefined)),
    showDrawerBackButton: inject(showDrawerBackButtonKey, ref(true)),
    isMobile: inject(isMobileKey, ref(false)),
    isSortDropwdownOpen: inject(isSortDropwdownOpenKey, ref(false)),
    productCount: inject(productCountKey, ref('')),
    toggleElementAnimation: inject(toggleElementAnimationKey, (_cssClass: string, _element:HTMLDivElement) => null),
    openFacetDrawer: inject(openFacetDrawerKey, () => null),
    closeFacetDrawer: inject(closeFacetDrawerKey, () => null),
    setSortOption: inject(setSortOptionKey, () => null),
    toggleFacetValue: inject(toggleFacetValueKey, () => null),
    clearAllFacets: inject(clearAllFacetsKey, () => null),
    clearFacet: inject(clearFacetKey, () => null),
    getFacetType: (facet: any) => {
      if (facet?.type?.endsWith("Option") || facet?.type === "#Category") {
        return "Checkbox";
      }
      if (facet?.type?.startsWith("#")) {
        return facet.type.replace("#","");
      }
      return facet?.type || "";
    },
  };
}